import { combineReducers } from "redux";
import authReducers from "./authReducers.js";
import alertReducers from "./alertReducer";

import studentReducer from "./studentReducer";
import coatchReducer from "./coatchReducer";
import courseReducer from "./courseReducer";
import faqReducer from "./faqReducer.js";
import contentManagement from "./cmsReducer.js";
import mediaManagement from "./mediaReducers.js";
import settingReducer from "./settingReducer";
import CourseCoachReducer from "./courseCoachReducer";
import CourseClassReducer from "./courseCoachClassReducer";
import CoursePackagesReducer from "./coursePackagesReducer";
import leaveReducer from "./leaveReducer";
import notificationReducer from "./notificationReducer";

export default combineReducers({
  auth: authReducers,
  alert: alertReducers,
  student: studentReducer,
  coatch: coatchReducer,
  course: courseReducer,
  faq: faqReducer,
  cmsDetails: contentManagement,
  mediaDetails: mediaManagement,
  setting: settingReducer,
  courseCoach: CourseCoachReducer,
  courseClass: CourseClassReducer,
  coursePackages: CoursePackagesReducer,
  leave: leaveReducer,
  notification: notificationReducer,
});
