import {
  GET_ALL_STUDENT,
  GET_ALL_ENROLLMENT,
  GET_ALL_MERITS,
  GET_REMARKS,
  GET_STUDENT_GROUPS,
  GET_ATTENDANCES
} from "../actions/types";

const initialState = {
  studentlist: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  enrollment_list: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  merits_list: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  remarks: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  studentLoading: true,
  meritsLoading: true,
  remarksLoading: true,
  attendancesLoading: true,
  attendances: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
  groups: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  groupsLoading:true
};

export default function student(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_STUDENT:
      return {
        ...state,
        studentlist: payload,
        loading: false,
      };
    case GET_ALL_ENROLLMENT:
      return {
        ...state,
        enrollment_list: payload,
        studentLoading: false,
      };
    case GET_ALL_MERITS:
      return {
        ...state,
        merits_list: payload,
        meritsLoading: false,
      };
    case GET_REMARKS:
      return {
        ...state,
        remarks: payload,
        remarksLoading: false,
      };
    case GET_ATTENDANCES:
      return {
        ...state,
        attendances: payload,
        attendancesLoading: false,
      };
    case GET_STUDENT_GROUPS:
      return {
        ...state,
        groups: payload,
        groupsLoading: false,
      };
    case GET_ALL_MERITS:
      return {
        ...state,
        merits_list: payload,
        meritsLoading: false,
      };
    default:
      return state;
  }
}
