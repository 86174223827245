import { GET_NOTIFICATION_LIST, NOTIFICATION_LOADING } from '../actions/types'

const initialState = {
  notification_list: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
}

const mediaManagement = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        loading: false,
        notification_list: payload,
      }
    case NOTIFICATION_LOADING:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}

export default mediaManagement
