export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const DELETE_ALL_ALERT = "DELETE_ALL_ALERT";
export const ADMIN_LOADED = "ADMIN_LOADED";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGOUT = "LOGOUT";

//
// GET_SETTINGS, SETTINGS_FAIL
export const GET_SETTINGS = "GET_SETTINGS";
export const SETTINGS_FAIL = "SETTINGS_FAIL ";

//
export const GET_ALL_STUDENT = "GET_ALL_STUDENT";
export const GET_STUDENT_GROUPS = "GET_STUDENT_GROUPS";
// enrollment
export const GET_ALL_ENROLLMENT = "GET_ALL_ENROLLMENT";

// Merits
export const GET_ALL_MERITS = "GET_ALL_MERITS";

// Coaches
export const GET_ALL_COATCH = "GET_ALL_COATCH";

// Course
export const GET_ALL_COURSE = "GET_ALL_COURSE";

// faq
export const FAQ_FAIL = "FAQ_FAIL";
export const GET_ALL_FAQ = "GET_ALL_FAQ";

export const GET_ALL_CMS = "GET_ALL_CMS";
export const GET_ALL_MEDIA = "GET_ALL_MEDIA";

export const GET_ALL_PACKAGES = "GET_ALL_PACKAGES";
export const GET_ALL_COURSE_COACH = "GET_ALL_COURSE_COACH";
export const GET_ALL_COURSE_CLASSES = "GET_ALL_COURSE_CLASSES";

export const GET_ALL_GIFT = "GET_ALL_GIFT";

export const GET_ALL_HISTORY = "GET_ALL_HISTORY";
export const GET_ALL_LEAVE_REQUEST = "GET_ALL_LEAVE_REQUEST";

export const GET_COACH_SALARY = "GET_COACH_SALARY";

export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const GET_REMARKS = "GET_REMARKS";
export const GET_ATTENDANCES = "GET_ATTENDANCES";
