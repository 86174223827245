import { GET_ALL_COURSE_CLASSES } from '../actions/types'

const initialState = {
  course_class: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
}

export default function classes(state = initialState, actions) {
  const { type, payload } = actions
  switch (type) {
    case GET_ALL_COURSE_CLASSES:
      return {
        ...state,
        course_class: payload,
        loading: false,
      }
    default:
      return state
  }
}
