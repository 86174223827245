import { GET_ALL_COATCH, GET_COACH_SALARY } from '../actions/types'

const initialState = {
  coatchList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  coatchSalary: {
    salary: [],
  },
  salaryLoading: true,
  loading: true,
}

export default function setting(state = initialState, actions) {
  const { type, payload } = actions
  switch (type) {
    case GET_ALL_COATCH:
      return {
        ...state,
        coatchList: payload,
        loading: false,
      }
    case GET_COACH_SALARY:
      return {
        ...state,
        coatchSalary: payload,
        salaryLoading: false,
      }
    default:
      return state
  }
}
