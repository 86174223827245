import { GET_ALL_HISTORY, GET_ALL_LEAVE_REQUEST } from '../actions/types'

const initialState = {
  leave_history: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  leave_request: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
  leave_req_loading: true,
}

export default function setting(state = initialState, actions) {
  const { type, payload } = actions
  switch (type) {
    case GET_ALL_HISTORY:
      return {
        ...state,
        leave_history: payload,
        loading: false,
      }
    case GET_ALL_LEAVE_REQUEST:
      return {
        ...state,
        leave_request: payload,
        leave_req_loading: false,
      }
    default:
      return state
  }
}
